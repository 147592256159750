import { IconButton } from '@mui/material';
import './content.css';
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const MY_EMAIL = ["akatary@mit.edu"];
const FROM_EMAIL_CONFIRM = "akatary@donotrespond.com"

export function Contact(props) {
  const sendMessage = () => {
    const messageInput = document.getElementById("message");
    const emailInput = document.getElementById("email");
    const subjectInput = document.getElementById("subject");
    const messageParts = [emailInput, subjectInput, messageInput];

    for (const messagePart of messageParts) {
      if (messagePart.value === "") {
        console.log(`${messagePart.id} is required to send email`)
        alert(`${messagePart.id} is required to send email`);
        return;
      }
    }

    const message = `${messageInput.value}\n`;
    fetch(`https://www.mit-msa.com:8443/mail/contact?name=''&email=${emailInput.value}&subject=${subjectInput.value}&message=${message}&reciepientEmails=${MY_EMAIL}&fromEmailConfirm=${FROM_EMAIL_CONFIRM}`, {
      // mode: 'no-cors',
      method: 'GET'
    })
    .then((res) => {
      console.log(res)
      if (res.status === 200) {
        console.log("Message sent!");
        alert("Message sent!");
        document.getElementById("sendBtn").classList.add("slide-20");
      } else {
        console.log("Message failed to deliver");
        alert("Message failed to deliver");
      }
    })
  }

  return (
    <div style={{color: "black"}} id="contact" className="none align-center justify-center width-100 height-100 column">
      <div>
          <input 
            id="email"
            placeholder="Email ..."
            style={{width: "max(15vw, 150px)", margin: "5px", padding: "5px", height: "max(1vw, 20px)", resize: "none", outline: "none", border: "none", backgroundImage: `url(${props.images("./grayConstruction.png").default})`}} 
            className="box-shadow no-border padding-10px"
            />
          <input 
            id="subject"
            placeholder="Subject ..."  
            style={{width: "max(15vw, 150px)", margin: "5px", padding: "5px", height: "max(1vw, 20px)", resize: "none", outline: "none", border: "none", backgroundImage: `url(${props.images("./grayConstruction.png").default})`}} 
            className="box-shadow no-border padding-10px"
            />
      </div>
      <textarea id="message" placeholder="Write your message here ..." style={{width: "max(25vw, 250px)", height: "max(15vw, 150px)", resize: "none", outline: "none", backgroundImage: `url(${props.images("./grayConstruction.png").default})`}} className="box-shadow no-border padding-10px margin-20px"></textarea>
      <IconButton color="primary" aria-label="send" id="sendBtn">
        <SendIcon className="align-self-start" onClick={sendMessage}/>
      </IconButton>
      <div className="flex align-center justicy-center">
        <IconButton color="primary" aria-label="send" onClick={() => window.location = "https://linkedin.com/in/ahmed-katary-0a54a8198"}>
          <LinkedInIcon fontSize="large" className="margin-10px" style={{color: "#000"}}/>
        </IconButton>
        <IconButton color="primary" aria-label="send" onClick={() => window.location = "https://github.com/ATKatary"}>
          <GitHubIcon fontSize="large" className="margin-10px" style={{color: "#000"}}/>
        </IconButton>
      </div>
    </div>
  )
}
